import { Container, Row, Col, Image } from "react-bootstrap";
import '../App.css';
import i18n from "../i18n";

/*function ProjectsScreen() {
    return (
        <Container className="mt-5 pb-5">
            <Row>
                <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc1") }} />
                    <Image src="/images/projects.jpg" fluid />
                </Col>
                <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc2") }} />
                </Col>
                <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc3") }} />
                </Col>
                <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc4") }} />
                </Col>
            </Row>
        </Container>
    );
}*/
function ProjectsScreen() {
    return (
        <Container className="mt-5 pb-5">
            <Row>
                <Col xs={12} className="mb-5">
                    <h6>BG14MFOP001-2.014</h6>
                    <hr/>
                    <h4>Подкрепа за производители на риба и други водни организми за преодоляване на икономическите последствия от пандемията COVID-19</h4>
                    <Image src="/images/projects.jpg" className="mb-3" fluid />
                    <p>„Фиш Фарм ЕЛАЗ” ЕООД е бенефициент по Оперативна програма „Морско дело и рибарство 2014-2020 г.“ Проект №: BG14MFOP001-2.014 „Подкрепа за производители на риба и други водни организми за преодоляване на икономическите последствия от пандемията COVID-19“ по мярка 2.6 „Мерки в областта на общественото здраве“. Административен договор № МДР-ИП-01-94/06.08.2020</p>
                    <p className="mt-5"><b>Бенефициент:</b> „Фиш Фарм ЕЛАЗ“ ЕООД</p>
                    <p><b>Срок за изпълнение на проекта:</b> 06.08.2020 г. - 06.12.2020 г.</p>
                    <p><b>Обща стойност:</b> 78,200.00 лв., от които 58,650.00 лв. европейско и 19,550.00 лв. национално съфинансиране</p>
                </Col>
                <Col xs={12} className="pt-5 mb-5">
                    <h6>BG14MFOP001-2.017</h6>
                    <hr/>
                    <h4>Подкрепа за производители на риба и други водни организми за преодоляване на икономическите последствия от пандемията COVID-19</h4>
                    <Image src="/images/projects.jpg" className="mb-3" fluid />
                    <p>„Фиш Фарм ЕЛАЗ” ЕООД е бенефициент по Оперативна програма „Морско дело и рибарство 2014-2020 г.“ Проект №: BG14MFOP001-2.017 „Подкрепа за производители на риба и други водни организми за преодоляване на икономическите последствия от пандемията COVID-19“ по мярка 2.6 „Мерки в областта на общественото здраве“.</p>
                    <p className="mt-5"><b>Бенефициент:</b> „Фиш Фарм ЕЛАЗ“ ЕООД</p>
                    <p><b>Срок за изпълнение на проекта:</b> 21.10.2021 г. - 21.11.2021 г.</p>
                    <p><b>Обща стойност:</b> 49,000.00 лв., от които 36,750.00 лв. европейско и 12,250.00 лв. национално съфинансиране</p>
                </Col>
                <Col xs={12} className="pt-5 mb-5">
                    <h6>BG14MFOP001-2.015 -0001</h6>
                    <hr/>
                    <h4>Ефективно използване на водните ресурси в Рибовъдно Стопанство „Бял кладенец“, за отглеждане на аквакултури в садки в яз. „Бял кладенец“, хидровъзел „Овчарица“ в землището на с.Ковачево, общ.Раднево</h4>
                    <Image src="/images/projects.jpg" className="mb-3" fluid />
                    <p>„Фиш Фарм ЕЛАЗ“ ЕООД в качеството си на Бенефициент подписа Административен договор № МДР-ИП-01-70 / 07.07.2021 г. за предоставяне на безвъзмездна финансова помощ, във връзка с изпълнението на проект „Ефективно използване на водните ресурси в Рибовъдно Стопанство „Бял кладенец“, за отглеждане на аквакултури в садки в яз. „Бял кладенец“, хидровъзел „Овчарица“ в землището на с.Ковачево, общ.Раднево“, финансиран по Програма за морско дело и рибарство, съфинансирана от Европейския съюз чрез Европейски фонд за морско дело и рибарство по процедура чрез подбор на проекти BG14MFOP001-2.015  „Продуктивни инвестиции в аквакултурите”, сектор „Малки проекти”, мярка 2.2  „Продуктивни инвестиции в аквакултурите“.</p>
                    <p className="mt-5"><b>Кратко описание на проекта:</b></p>
                    <p>„Фиш Фарм ЕЛАЗ“ ЕООД е микро предприятие с основна дейност аквакултурно производство. Дружеството осъществява дейност в Рибовъдно Стопанство „Бял кладенец“, за отглеждане на аквакултури в садки в яз. „Бял кладенец“, хидровъзел „Овчарица“ в землището на с.Ковачево, общ.Раднево. „Фиш Фарм ЕЛАЗ“ ЕООД планира да закупи следното оборудване, свързано с дейността му: мрежи за садки и смукателна помпа за люпилня с дебит между 20 и 22 куб.м/ч. Мрежите за садки ще отговарят на съответните стандарти в сферата на аквакултурното производство и са предвидени за ползване при отглеждането на аквакултури – шаран и американски сом. Закупуването на посоченото оборудване ще ни предостави финансова и икономическа независимост, като ще способства за подобряване на конкурентноспособността и жизнеспособността на „Фиш Фарм ЕЛАЗ“ ЕООД.</p>
                    <p>Реализирането на предвидените инвестиции e насоченo към постигане на следните цели:</p>
                    <ul><li>Модернизиране рибното стопанство, в резултат на закупуването на Мрежи за садки в синхрон със съответните стандарти в сферата на аквакултурното производство и внедряване на Иновации - ФИЛТЪР УТАИТЕЛ, клас по МПК: В01D35/02, номер на заявка: 4363, дата на заявяване: 11.06.2019 г., защитен номер: 3470, дата на издаване/регистрация: 16.01.2020 г.</li><li>Инвестиране в репродуктивно-производствения процес и подобряване на производствените характеристики на стопанството без да се увеличава капацитета на стопанството.</li></ul>
                    <p>Изпълнението на дейностите по проекта ще доведе до положителния резултат „Фиш Фарм ЕЛАЗ“ ЕООД да използва ефективно природните ресурсите, подобряване на конкурентоспособността и жизнеспособността на предприятието в сектора на аквакултурите, включително и повишаване положителното въздействие върху околната среда.</p>
                    <p className="mt-5"><b>Бенефициент:</b> „Фиш Фарм ЕЛАЗ“ ЕООД</p>
                    <p><b>Срок за изпълнение на проекта:</b> 07.07.2021 г. до 07.01.2022 г.</p>
                    <p><b>Обща стойност:</b> 97,656.12 лв., от които безвъзмездна финансова помощ в размер 48,828.06 лв., като 36,621.05 лв. европейско и 12,207.01 лв. национално съфинансиране.</p>
                    <p className="mt-5">Пакет документи за участие в процедура за избор с публична покана  с предмет: "Доставка на мрежи за садки за нуждите на Рибовъдно Стопанство "Бял Кладенец", във връзка с изпълнение на Проект „Ефективно използване на водните ресурси в Рибовъдно Стопанство „Бял кладенец“, за отглеждане на аквакултури в садки в яз. „Бял кладенец“, хидровъзел „Овчарица“ в землището на с.Ковачево, общ.Раднево“, който се реализира  с финансовата подкрепа на Програма за морско дело и рибарство 2014 – 2020 г., съфинансирана от Европейския съюз чрез Европейски фонд за морско дело и рибарство, по процедура чрез подбор на проекти BG14MFOP001-2.015  „Продуктивни инвестиции в аквакултурите”, сектор „Малки проекти”, мярка 2.2 „Продуктивни инвестиции в аквакултурите“, Административен Договор № МДР-ИП-01-70 / 07.07.2021 г. за предоставяне на безвъзмездна финансова помощ.</p>
                    <p>Пакетът документи може да се изтегли от <a href="/doc/paket_dokumenti.rar" download>тук</a>.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default ProjectsScreen;
