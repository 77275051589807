import Helmet from 'react-helmet';
import { Switch, Route, useLocation } from 'react-router-dom';
import '../App.css';
import i18n from "../i18n";
import CONSTANTS from '../CONSTANTS';
import AboutScreen from '../screens/AboutScreen';
import Carp from '../screens/Carp';
import Catfish from '../screens/Catfish';
import ContactsScreen from '../screens/ContactsScreen';
import FishScreen from '../screens/FishScreen';
import GalleryScreen from '../screens/GalleryScreen';
import HomeScreen from '../screens/HomeScreen';
import NoMatchScreen from '../screens/NoMatchScreen';
import ProjectsScreen from '../screens/ProjectsScreen';
import Footer from './Footer';
import SectionHeader from './SectionHeader';
import Cupid from '../screens/Cupid';

const ROUTES = CONSTANTS.ROUTES;

function Main() {
  let location = useLocation();

  return (
    <main id="main-content">
      <Helmet>
        <meta
          name="description"
          content={i18n.t("metadescription")}
        />
        <meta
          name="author"
          content={i18n.t("metaauthor")}
        />
      </Helmet>

      <SectionHeader />

      <Switch>

        <Route exact path={ROUTES.home.path}>
          <Helmet>
            <title>{i18n.t("fishfarmelaz") + ' | ' + i18n.t(ROUTES.home.title)}</title>
          </Helmet>
          <HomeScreen />
        </Route>

        <Route exact path={ROUTES.fish.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.fish.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <FishScreen />
        </Route>

        <Route exact path={ROUTES.carp.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.carp.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <Carp />
        </Route>

        <Route exact path={ROUTES.catfish.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.catfish.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <Catfish />
        </Route>

        <Route exact path={ROUTES.cupid.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.cupid.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <Cupid />
        </Route>

        <Route exact path={ROUTES.about.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.about.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <AboutScreen />
        </Route>

        <Route exact path={ROUTES.projects.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.projects.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <ProjectsScreen />
        </Route>

        <Route exact path={ROUTES.gallery.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.gallery.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <GalleryScreen />
        </Route>

        <Route exact path={ROUTES.contacts.path}>
          <Helmet>
            <title>{i18n.t(ROUTES.contacts.title) + ' | ' + i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <ContactsScreen />
        </Route>

        <Route path="*">
          <Helmet>
            <title>{i18n.t("fishfarmelaz")}</title>
          </Helmet>
          <NoMatchScreen />
        </Route>
      </Switch>

      {
        location.pathname === "" || location.pathname === "/" ? null : (<Footer />)
      }

    </main>
  );
}

export default Main;
