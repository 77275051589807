import '../App.css';

function NoMatchScreen() {
    return (
        <div className='parallax__wrapper'>
            
        </div>
    );
}

export default NoMatchScreen;
