import '../App.css';
import { Col, Container, Row } from 'react-bootstrap';
import i18n from "../i18n";
import { Link } from "react-router-dom";
import Footer from '../components/Footer';

function HomeScreen() {
    return (
        <div className='parallax__wrapper'>
            <div className="parallax__group hero-container">
                <div className="parallax__layer sky">
                    <img src="/images/bg.png" alt="Home screen background" />
                </div>
                <div className="parallax__layer grass"></div>
                <div className="parallax__layer hero-text">
                    <div className='home-title-wrapper'>
                        <div className='home-title-block bg-color-accent'></div>
                        <h1 className='home-title'>
                            FISH FARM
                            <br />
                            <span className='color-accent'>ELAZ</span>
                        </h1>
                        
                    </div>
                    <div className='icon-scroll'></div>
                </div>
            </div>
            <div className="parallax__group info-container">
                <Link to="/about">
                    <button as={Link} className='home-btn'>
                        {i18n.t("more")}
                    </button>
                </Link>
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">
                            <p className='home-desc'>
                                Рибовъдно стопанство "Елаз" е единственото в България, което разработва специална технология за люпене на ранна личинка от сладководни риби през месец Януари.
                            </p>
                        </Col>
                        <Col>
                            <h1 className='color-accent'>100+</h1>
                            <h4 className='text-uppercase'>{i18n.t("floatingCages")}</h4>
                        </Col>
                        <Col>
                            <h1 className='color-accent'>450T</h1>
                            <h4 className='text-uppercase'>{i18n.t("biomass")}</h4>
                        </Col>
                        <Col>
                            <h1 className='color-accent'>3</h1>
                            <h4 className='text-uppercase'>{i18n.t("successfulProjects")}</h4>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="parallax__group footer-container">
                <Footer />
            </div>
        </div>
    );
}

export default HomeScreen;
