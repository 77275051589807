import { Container, Row, Col, Image } from "react-bootstrap";
import '../App.css';
import {
    LightgalleryProvider,
    LightgalleryItem
} from "react-lightgallery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons'

const PhotoItem = ({ image, thumb, group }) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
        <div className="gallery-img-wrapper">
            <Image src={thumb} className="gallery-img" />
            <div className="overlay">
                <div className="overlay-content">
                    <FontAwesomeIcon icon={faExpand} size="3x" />
                </div>
            </div>
        </div>
    </LightgalleryItem>
);

const IMAGES = [
    [
        "/images/gallery/1.jpg",
        "/images/gallery/thumb1.jpg",
    ],
    [
        "/images/gallery/2.jpg",
        "/images/gallery/thumb2.jpg",
    ],
    [
        "/images/gallery/3.jpg",
        "/images/gallery/thumb3.jpg",
    ],
    [
        "/images/gallery/4.jpg",
        "/images/gallery/thumb4.jpg",
    ],
    [
        "/images/gallery/5.jpg",
        "/images/gallery/thumb5.jpg",
    ],
    [
        "/images/gallery/6.jpg",
        "/images/gallery/thumb6.jpg",
    ],
    [
        "/images/gallery/7.jpg",
        "/images/gallery/thumb7.jpg",
    ],
    [
        "/images/gallery/8.jpg",
        "/images/gallery/thumb8.jpg",
    ],
    [
        "/images/gallery/9.jpeg",
        "/images/gallery/thumb9.jpg",
    ],
    [
        "/images/gallery/10.jpg",
        "/images/gallery/thumb10.jpg",
    ],
    [
        "/images/gallery/11.jpg",
        "/images/gallery/thumb11.jpg",
    ],
    [
        "/images/gallery/12.jpg",
        "/images/gallery/thumb12.jpg",
    ],
    [
        "/images/gallery/13.jpg",
        "/images/gallery/thumb13.jpg",
    ],
    [
        "/images/gallery/14.jpg",
        "/images/gallery/thumb14.jpg",
    ],
    [
        "/images/gallery/15.jpg",
        "/images/gallery/thumb15.jpg",
    ],
    [
        "/images/gallery/16.jpg",
        "/images/gallery/thumb16.jpg",
    ],
    [
        "/images/gallery/17.jpg",
        "/images/gallery/thumb17.jpg",
    ],
    [
        "/images/gallery/18.jpg",
        "/images/gallery/thumb18.jpg",
    ],
];

function GalleryScreen() {
    return (
        <Container className="mt-5 pb-5 mb-5">
            <Row>
                <Col>
                    <LightgalleryProvider>
                        <Row className="gallery-row">
                            {
                                IMAGES.map((p, idx) => (
                                    <Col key={'group1' + idx} xs={6} md={4} lg={3} className="gallery-col">
                                        <PhotoItem image={p[0]} thumb={p[1]} group="group1" />
                                    </Col>
                                ))
                            }
                        </Row>
                    </LightgalleryProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default GalleryScreen;
